<template>
    <page>
        <v-breadcrumbs slot="breadcrumb" class="pa-0">
            <v-breadcrumbs-item exact :to="{name:'dashboard'}">Início</v-breadcrumbs-item>
            <v-breadcrumbs-divider>></v-breadcrumbs-divider>
            <v-breadcrumbs-item exact :to="{name:'notas_fiscais'}">Notas Fiscais</v-breadcrumbs-item>
        </v-breadcrumbs>
        <template v-if="notaFiscal">
            <lembretes-fragment ref="lembretesFragment" :nota-fiscal="notaFiscal" />

            <v-alert v-if="notaFiscal.dataPagamento" class="py-1" dark :color="(pagamentoFuturo | pagamentoParaHoje) ? 'orange' : 'blue'" >
                <span class="caption font-weight-regular" v-if="pagamentoFuturo">Nota fiscal será compensada em </span>
                <span class="caption font-weight-regular" v-else-if="pagamentoParaHoje">Nota fiscal será paga hoje </span>
                <span class="caption font-weight-regular" v-else>Nota fiscal paga em </span>
                <span class="caption font-weight-bold" v-if="!pagamentoParaHoje">
                    {{notaFiscal.dataPagamento | moment('DD [de] MMMM [de] YYYY')}}
                </span>
            </v-alert>
            <div>
                <span class="title font-weight-regular d-inline-flex" style="line-height: normal; align-items: center">
                    Nº: {{notaFiscal.numero}}
                    <v-menu :close-on-content-click="false"
                            offset-y open-on-hover>
                                <template v-slot:activator="{ on }">
                                    <v-btn class="ml-2" small icon color="grey" outlined v-on="on">
                                        <v-icon >mdi-information-variant</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="font-weight-light">Lote de Serviço</v-list-item-title>
                                            <v-list-item-subtitle class="font-weight-bold">{{notaFiscal.servico.loteServico.codigo}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="font-weight-light">Número do RPS</v-list-item-title>
                                            <v-list-item-subtitle class="font-weight-bold">{{notaFiscal.rpsNumero}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="font-weight-light">Série da Nota Fiscal</v-list-item-title>
                                            <v-list-item-subtitle class="font-weight-bold">{{notaFiscal.rpsSerie}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="font-weight-light">Natureza da Operação</v-list-item-title>
                                            <v-list-item-subtitle class="font-weight-bold">{{formatNaturezaDaOperacao(notaFiscal.exigibilidadeIss)}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="font-weight-light">Código de Autenticidade</v-list-item-title>
                                            <v-list-item-subtitle class="font-weight-bold">{{notaFiscal.codigoVerificacao}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>

                    <v-menu offset-y >
                        <template v-slot:activator="{ on }">
                            <v-btn class="ml-2" small icon color="grey" outlined v-on="on">
                                <v-icon small>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="$refs.lembretesFragment.newLembreteDialog()">
                                <v-list-item-title>Criar Lembrete</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-btn :loading="downloadingDanfe" :disabled="downloadingDanfe"
                        class="ml-2" small outlined icon color="indigo" @click="downloadDanfe">
                        <v-icon small>mdi-file-download-outline</v-icon>
                    </v-btn>
                </span>
                <div style="opacity: 0.6; float: right" class="d-inline-flex align-center">
                    <div class="d-flex align-center">

                        <div>
                            <p class="mb-0 grey--text " style="font-size:10px; line-height: 12px">Data Emissão</p>
                            <p class="mb-0 caption grey--text text--darken-2" style="line-height: 12px">{{notaFiscal.createdAt | moment("DD/MM/YYYY HH:mm")}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <span class="caption text-uppercase">{{notaFiscal.discriminacao}}</span>

            <div class="d-flex mt-6  mb-6">
                <v-alert v-if="notaFiscal.valorPago" class="text-right mb-0 mr-4 py-0" text color="blue" >
                    <span class="overline">Valor Pago</span>
                    <span class="headline d-block">
                            {{notaFiscal.valorPago | formatNumber}}
                        </span>
                </v-alert>

                <v-alert class="text-right mb-0 mr-4 py-0" text color="teal" >
                    <span class="overline">Total Serviços</span>
                    <span class="headline d-block">
                            {{notaFiscal.valorServicos | formatNumber}}
                        </span>
                </v-alert>

                <v-alert class="text-right mb-0  mr-4 py-0" text color="teal">
                    <span class="overline">Valor Líquido</span>
                    <span class="headline d-block">
                            {{notaFiscal.valorLiquidoNfse | formatNumber}}
                        </span>
                </v-alert>

                <v-alert class="text-right mb-0 mr-4  py-0" text color="blue-grey lighten-2">
                    <span class="overline">Total Retenções</span>
                    <span class="headline d-block">
                            {{totalRetencoes | formatNumber}}
                        </span>
                </v-alert>

                <v-alert class="text-right mb-0 py-0" text color="blue-grey lighten-2">
                    <span class="overline">Total Descontos</span>
                    <span class="headline d-block">
                            {{totalDescontos | formatNumber}}
                        </span>
                </v-alert>


            </div>

            <v-card outlined class="mb-6">
                <v-card-title class="pb-0">Tomador</v-card-title>
                <v-card-text class="black--text">
                    <v-row no-gutters>
                        <v-col cols="3">
                            <span class="label">CNPJ/CPF</span>
                            {{formatCNPJ(notaFiscal.tomadorCnpj)}}
                        </v-col>
                        <v-col cols="3">
                            <span class="label">Inscrição Estadual</span>

                        </v-col>
                        <v-col cols="3">
                            <span class="label">Inscrição Municipal</span>

                        </v-col>
                        <v-col cols="3">
                            <span class="label">Razão Social</span>
                            {{notaFiscal.tomadorRazaoSocial}}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="3">
                            <span class="label">Endereço</span>
                            {{notaFiscal.tomadorEndereco}}
                        </v-col>
                        <v-col cols="3">
                            <span class="label">Número</span>
                            {{notaFiscal.tomadorNumero}}
                        </v-col>
                        <v-col cols="3">
                            <span class="label">Complemento</span>
                            {{notaFiscal.tomadorComplemento}}
                        </v-col>
                        <v-col cols="3">
                            <span class="label">Bairro</span>
                            {{notaFiscal.tomadorBairro}}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="3">
                            <span class="label">CEP</span>
                            {{notaFiscal.tomadorCep}}
                        </v-col>
                        <v-col cols="3">
                            <span class="label">Cidade</span>
                            <router-link :to="{ name: 'cidade_view', params: { id: parseInt(notaFiscal.tomadorCidade.id) }}">
                            {{notaFiscal.tomadorCidade.nome}}
                            </router-link>
                        </v-col>
                        <v-col cols="1">
                            <span class="label">UF</span>
                            {{notaFiscal.tomadorUf}}
                        </v-col>
                        <v-col cols="2">
                            <span class="label">Telefone</span>

                        </v-col>
                        <v-col cols="2">
                            <span class="label">Email</span>

                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card outlined class="mb-6">
                <v-card-title class="pb-0">ISSQN - Imposto Sobre Serviços de Qualquer Natureza</v-card-title>
                <v-card-text class="black--text">
                    <v-row no-gutters>
                        <v-col cols="3">
                            <span class="label">Valor total dos Serviços</span>
                            {{notaFiscal.valorServicos | formatNumber}}
                        </v-col>
                        <v-col cols="3">
                            <span class="label">Base de Cálculo</span>
                            {{notaFiscal.baseCalculo | formatNumber}}
                        </v-col>
                        <v-col cols="3">
                            <span class="label">Desconto Incondicionado</span>
                            {{notaFiscal.descontoCondicionado | formatNumber}}
                        </v-col>
                        <v-col cols="3">
                            <span class="label">Desconto Condicionado</span>
                            {{notaFiscal.descontoIncondicionado | formatNumber}}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="3">
                            <span class="label">Deduções (Material)</span>
                            {{notaFiscal.valorDeducoes | formatNumber}}
                        </v-col>
                        <v-col cols="3">
                            <span class="label">Deduções Base de Cálculo</span>
                            {{0 | formatNumber}}
                        </v-col>
                        <v-col cols="3">
                            <span class="label">ISSQN Devido</span>
                            {{notaFiscal.valorIss | formatNumber}}
                        </v-col>
                        <v-col cols="3">
                            <span class="label">ISSQN Retido</span>
                            {{notaFiscal.issRetido ? "SIM" : "NÃO"}}
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card outlined class="mb-6">
                <v-card-title class="pb-0">Retenções na Fonte</v-card-title>
                <v-card-text class="black--text">
                    <v-row no-gutters>
                        <v-col cols="2">
                            <span class="label">PIS</span>
                            {{notaFiscal.valorPis | formatNumber}}
                        </v-col>
                        <v-col cols="2">
                            <span class="label">COFINS</span>
                            {{notaFiscal.valorCofins | formatNumber}}
                        </v-col>
                        <v-col cols="2">
                            <span class="label">INSS</span>
                            {{notaFiscal.valorInss | formatNumber}}
                        </v-col>
                        <v-col cols="2">
                            <span class="label">IRRF</span>
                            {{notaFiscal.valorIrrf | formatNumber}}
                        </v-col>
                        <v-col cols="2">
                            <span class="label">CSLL</span>
                            {{notaFiscal.valorCsll | formatNumber}}
                        </v-col>
                        <v-col cols="2">
                            <span class="label">Outras Retenções</span>
                            {{notaFiscal.outrasRetencoes | formatNumber}}
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card outlined class="mb-6">
                <v-card-title class="pb-0">Informações Complementares</v-card-title>
                <v-card-text class="black--text">
                    <v-row no-gutters>
                        <v-col>
                            {{notaFiscal.outrasInformacoes}}
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card outlined class="">
                <v-card-title class="pb-0">Serviços</v-card-title>
                <v-card-text class="pa-0">
                    <!--<v-list class="py-0" :three-line="$vuetify.breakpoint.smAndUp" :two-line="$vuetify.breakpoint.xsOnly" v-if="agencia.fiscalizacoes && agencia.fiscalizacoes.length > 0">-->
                    <v-list class="mt-2 py-0" two-line v-if="notaFiscal.servico.fiscalizacoes">
                        <template v-for="(fiscalizacao, index) in notaFiscal.servico.fiscalizacoes">
                            <v-list-item :dense="$vuetify.breakpoint.xsOnly" :key="fiscalizacao.id"  :to="{ name: 'fiscalizacao_view', params: { id: parseInt(fiscalizacao.id) }}" >
                                <v-list-item-avatar>
                                    <v-icon class="light-green lighten-3" v-text="getItemIcon(fiscalizacao.tipo)"></v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-if="!$vuetify.breakpoint.xsOnly" v-html="fiscalizacao.financiamento.mutuario"></v-list-item-title>
                                    <v-list-item-title v-else class="body-2" v-html="fiscalizacao.financiamento.mutuario"></v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{formatDistanciaPercorrida(fiscalizacao.distanciaPercorrida)}}
                                    </v-list-item-subtitle>

                                </v-list-item-content>

                                <v-list-item-action v-if="!$vuetify.breakpoint.xsOnly">
                                    <v-list-item-action-text>Data Fiscalização: <b>{{new Date(fiscalizacao.dataFiscalizacao) | moment("DD MMM YYYY")}}</b></v-list-item-action-text>
                                </v-list-item-action>

                            </v-list-item>

                            <v-divider ></v-divider>
                        </template>
                    </v-list>
                    <div v-else-if="loading === false && (agencia.fiscalizacoes === null || agencia.fiscalizacoes.length === 0)" class="text-center pa-6">
                        <h1 class="display-3 mb-4">Ops!</h1>
                        <span class="headline font-weight-light">Nenhuma fiscalização executada para essa agência!</span>
                    </div>
                </v-card-text>
            </v-card>
        </template>
        <template v-if="notaFiscalEmitidaSemWebService">
            Não foi possível processar essa nota fiscal. Contate o administrador.
        </template>
    </page>
</template>

<script>
    // @ts-ignore
    import page from '../../../components/Page';
    import {getIconByFiscalizacaoTipo} from "@/assets/FiscaizacaoUtil";
    import gql from "graphql-tag";
    // @ts-ignore
    import LembretesFragment from "./fragments/view/LembretesFragment";
    export default {
        name: "ViewNotaFiscal",
        components: {
            LembretesFragment,
            page,
        },
        data() {
            return {
                notaFiscal: null,
                notaFiscalEmitidaSemWebService: false,
                downloadingDanfe: false,
            }
        },
        mounted() {
            this.getNotaFiscal();
        },
        computed:{
            totalRetencoes(){
                return this.notaFiscal.valorPis +
                    this.notaFiscal.valorCofins +
                    this.notaFiscal.valorInss +
                    this.notaFiscal.valorIr +
                    this.notaFiscal.valorCsll;
            },
            totalDescontos(){
                return this.notaFiscal.descontoCondicionado +
                    this.notaFiscal.descontoIncondicionado;
            },
            pagamentoFuturo(){
                return this.$moment(this.notaFiscal.dataPagamento).isAfter(new Date());
            },
            pagamentoParaHoje(){
                return this.$moment(this.notaFiscal.dataPagamento).isSame(new Date(), 'day', 'month', 'year');
            }
        },
        methods: {
            getNotaFiscal(){
                const id = this.$route.params.id;
                this.$apollo.query({
                    query: gql`query($id: Int!) {
                        notaFiscal(id: $id){
                            id
                            numero
                            exigibilidadeIss
                            codigoVerificacao
                            rpsNumero
                            rpsSerie
                            rpsDataEmissao
                            tomadorCnpj
                            tomadorRazaoSocial
                            tomadorEndereco
                            tomadorNumero
                            tomadorBairro
                            tomadorCep
                            tomadorCidade{
                                id
                                nome
                            }
                            tomadorUf
                            discriminacao
                            valorDeducoes
                            valorPis
                            valorCofins
                            valorInss
                            valorIr
                            valorCsll
                            valorIss
                            outrasRetencoes
                            descontoCondicionado
                            descontoIncondicionado
                            valorServicos
                            baseCalculo
                            valorDeducoes
                            valorLiquidoNfse
                            issRetido
                            outrasInformacoes
                            dataPagamento
                            valorPago
                            arquivos{
                                id
                            }
                            servico{
                                id
                                loteServico{
                                    codigo
                                }
                                fiscalizacoes{
                                    id
                                    dataFiscalizacao
                                    tipo{
                                        nome
                                    }
                                    financiamento{
                                        mutuario
                                    }
                                    distanciaPercorrida
                                }
                            }
                            lembretes{
                                id
                                descricao
                                criadoPor{
                                    id
                                    nome
                                    sobrenome
                                    imagem{
                                        id
                                    }
                                }
                                createdAt
                            }
                            createdAt
                        }
                    }`,
                    variables: {
                        id
                    }
                })
                .then((result) => {
                    this.notaFiscal = result.data.notaFiscal;
                    document.title = "Nº: " + this.notaFiscal.numero + " - JF Assessoria Rural"
                })
                .catch((error) => {
                    if(error.graphQLErrors[0].message.includes("E4")){
                        this.notaFiscalEmitidaSemWebService = true;
                    }
                })
            },
            formatNaturezaDaOperacao(exigibilidadeIss){
                switch (exigibilidadeIss){
                    case 1:
                        return "Exigível"
                    default:
                        return ""
                }
            },
            formatCNPJ(cnpj){
                return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1 $2 $3/$4-$5")
            },
            getItemIcon(tipo){
                return getIconByFiscalizacaoTipo(tipo);
            },
            formatDistanciaPercorrida(distancia){
                if(distancia > 500){
                    return "Distância excedida: " + (distancia - 500) + " km"
                }else{
                    return "Não há distância excedida";
                }
            },
            downloadDanfe() {
                this.downloadingDanfe = true;
                this.$axios.get('/notas_fiscais/' + this.notaFiscal.id + '/download_danfe', {responseType: 'blob'}).then((response) => {
                    const fileLink = document.createElement('a');
                    fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
                    const fileName = response.headers['content-disposition'].split('filename=')[1];
                    fileLink.setAttribute('download', fileName);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    document.body.removeChild(fileLink);
                    this.downloadingDanfe = false;
                }).catch(() => {
                    this.downloadingDanfe = false;
                    this.$dialog.error({
                        text: 'Não foi possível baixar o arquivo. Contate o desenvolvedor.',
                        title: 'Ops',
                    });

                });
            },
        }
    }
</script>

<style scoped>
    .label{
        color: #616161 !important;
        caret-color: #616161 !important;

        font-size: 0.75rem !important;
        font-weight: 400;
        letter-spacing: 0.0333333333em !important;
        line-height: 1.25rem;

        display: block !important;
    }
</style>