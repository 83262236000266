import { render, staticRenderFns } from "./View.vue?vue&type=template&id=89d465b8&scoped=true&"
import script from "./View.vue?vue&type=script&lang=js&"
export * from "./View.vue?vue&type=script&lang=js&"
import style0 from "./View.vue?vue&type=style&index=0&id=89d465b8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89d465b8",
  null
  
)

export default component.exports